/**
 * 
 * @description This file is used by BI Renderers to provide common functionality.
 * Warning: Donot use this file directly on any components, other than BI Renderers.
 * 
 */

import Vue from 'vue';

export const utils = {
	computed: {
		icon() {
			return this.params.icon
		},
		component() {
			return this.params.component
		},
		componentProps() {
			return {
				...(this.component?.userDefinedProps ?? {})
			}
		}
	},
    methods: {
        invokeCallback(eventName, event) {
			this.params.context.parent.dispatchEvent({
				type: eventName,
				event: this.params
			})
		},
		getListeners(natives) {
			return {
				...natives,
				click: (e) => {
					this.invokeCallback(`on${this.params.iconName}Click`, e)
					natives.click(e)
				}
			}
		}
    }
}

/**
 * Note: Include utils mixin before using essentials
 */
export const essentials = {
	created() {
		if(this.component?.definition) {
			const parentContext = this
			this.mountedComponent = Vue.extend({
				extends: this.component.definition,
				data() {
					return {
						params: Object.freeze(parentContext.params)
					}
				}
			})
		}
	},
	beforeDestroy() {
		this.mountedComponent = null
	}
}