<script type="text/babel">
import HBIcon from '../../../aviary/HbIcon.vue'
import { utils, essentials } from '../../../mixins/BI/rendererMixin'

export default {
	components: {
		HBIcon
	},
	data() {
		return {
			// Mounted component strategies will be handled inside essentials mixin
			mountedComponent: null
		}
	},
	mixins: [utils, essentials],
}
</script>

<template>
	<component
		v-bind="{params: {...params, ...componentProps}}"
		:is="mountedComponent"
	>
	</component>
</template>