<script type="text/babel">
import HBIcon from '../../../aviary/HbIcon.vue'
import { utils, essentials } from '../../../mixins/BI/rendererMixin'

export default {
    components: {
		HBIcon
	},
    data() {
        return {
            // Mounted component strategies will be handled inside essentials mixin
            mountedComponent: null
        }
    },
    mixins: [utils, essentials],
    methods: {}
}
</script>

<template>
    <v-tooltip bottom open-delay="50" color="transparent">
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs"> {{ icon }} </v-icon>
        </template>
        <component
            v-if="mountedComponent"
            v-bind="componentProps"
            :is="mountedComponent"
        />
    </v-tooltip>
</template>
