<script type="text/babel">
import HBIcon from '../../../aviary/HbIcon.vue'
import { utils, essentials } from '../../../mixins/BI/rendererMixin'

export default {
	components: {
		HBIcon
	},
	data() {
		return {
			// Mounted component strategies will be handled inside essentials mixin
			mountedComponent: null
		}
	},
	mixins: [utils, essentials],
}
</script>

<template>
	<v-menu offset-y>
		<template v-slot:activator="{ on, attrs }">
			<hb-icon v-on="getListeners(on)" v-bind="attrs">
				{{ icon }}
			</hb-icon>
		</template>
		<component
			v-bind="componentProps"
			:is="mountedComponent"
		/>
	</v-menu>
</template>