<!-- BCT: New file created by BCT for space-management -->
<template>
  <div>
    <hb-modal
      size="large"
      title="Filter Spaces"
      v-model="dialog"
      @close="onCloseFilterDialog"
      :footerCancelOption="true"
      show-help-link
    >
      <!-- Spinning Loader -->
      <template v-slot:content v-if="loading">
        <div class="text-center px-10 py-10">
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>

      <!-- Main content -->
      <template v-slot:content v-else>
        <hb-modal
          v-model="showConfirmationDialog"
          size="medium"
          title="Discard Setup"
          confirmation
          @close="onCloseConfirmationDialog"
          show-help-link
        >
          <!-- Confirmation Modal -->
          <template v-slot:content>
            <div class="px-6 py-4">
              Are you sure you want to discard this setup?
            </div>
            <div class="px-6 pb-4">
              If you do, you will lose all the information you’ve entered.
              Continue the setup process to ensure Hummingbird saves your
              information.
            </div>
          </template>
          <template v-slot:right-actions>
            <hb-btn color="primary" @click="$emit('close')">Discard</hb-btn>
          </template>
        </hb-modal>
        <!-- Tabs -->
        <div>
          <div
            class="add-border text-grey pa-5 ma-2"
            v-if="!amenityList || Object.keys(amenityList).length === 0"
          >
            There are no features and amenities. First, add features and
            amenities from the settings page.
          </div>
          <div v-else>
            <template>
              <div class="px-4 py-4 text-grey">
                Filter your spaces by the selected features and amenities.
              </div>
              <v-col cols="12" class="pa-0">
                <v-divider></v-divider>
              </v-col>
            </template>
            <div v-for="(amenities, category, index) in amenityList" :key="index">
              <hb-form :label="category" full>
                <v-row :key="aIndex" v-for="(amenity, aIndex) in amenities">
                  <v-col :class="['pt-0', aIndex === amenities.length - 1 ? 'pb-1' : 'pb-2']">
                    <hb-checkbox
                      class="mt-0"
                      :value="amenity.selected"
                      v-model="amenity.selected"
                      hide-details
                      dense
                    >
                      <template v-slot:label>
                        <span class="hb-text-night">
                          {{ amenity.feature }}
                        </span>
                      </template>
                    </hb-checkbox>
                  </v-col>
                  <v-col v-if="amenity.selected" :class="['pt-0', aIndex === amenities.length - 1 ? 'pb-0' : 'pb-1']" cols="5">
                    <v-select
                      v-if="amenity.options.type === 'select'"
                      :items="amenity.options.options"
                      v-model="amenity.property_default_value"
                      item-value="id"
                      item-text="name"
                      label="Select a value"
                      :id="amenity.feature"
                      :name="amenity.feature"
                      :error-messages="errors.collect(`${amenity.feature}`)"
                      v-validate="'required'"
                      :data-vv-name="amenity.feature"
                      :data-vv-as="amenity.feature"
                      hide-details
                      single-line
                      style="width: 200px;"
                    >
                    </v-select>
                    <v-radio-group
                      v-else-if="amenity.options.type === 'checkbox'"
                      row
                      v-model="amenity.property_default_value"
                      :id="amenity.feature"
                      :name="amenity.feature"
                      :data-vv-name="amenity.feature"
                      :data-vv-as="amenity.feature"
                      :error-messages="errors.collect(`${amenity.feature}`)"
                      v-validate="'required'"
                      hide-details
                    >
                      <hb-radio value="Yes" class="pb-1">
                        <template v-slot:label>
                          <span class="text-capitalize">Yes</span>
                        </template>
                      </hb-radio>
                      <hb-radio value="No" class="pb-1">
                        <template v-slot:label>
                          <span class="text-capitalize">No</span>
                        </template>
                      </hb-radio>
                    </v-radio-group>
                    <v-text-field
                      v-else
                      type="text"
                      v-model="amenity.property_default_value"
                      :label="amenity.name"
                      dense
                      class="mt-0"
                      :id="amenity.feature"
                      :name="amenity.feature"
                      v-validate="'required'"
                      :data-vv-name="amenity.feature"
                      :data-vv-as="amenity.feature"
                      :error-messages="errors.collect(`${amenity.feature}`)"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </hb-form>
            </div>
          </div>
        </div>
        <!-- <amenity-selector
          :amenity-category-map="amenityCategoryMap"
        ></amenity-selector> -->
      </template>
      <template v-slot:left-actions>
        <a class="ml-1 hb-link" @click="resetFilter">
          Reset Filter
        </a>
      </template>
      <template v-slot:right-actions>
        <span class="">
          <hb-btn
            class="mr-1"
            color="primary"
            type="submit"
            @click="onClickFilterSpaces"
            >Filter Spaces</hb-btn
          >
        </span>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import api from "../../../assets/api.js";
import { mapGetters } from "vuex";
import Vue from "vue";
import { notificationMixin } from  '@/mixins/notificationMixin.js';

export default {
  name: "AmenitiesStickyFilter",
  props: {
    value: {
      type: Boolean
    },
    selectedAmenitites: { type: Array }
  },
  components: {
    // AmenitySelector
  },
  mixins: [notificationMixin],

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set() {}
    },
    ...mapGetters({
      properties: "propertiesStore/filtered"
    })
  },
  data() {
    return {
      loading: false,
      showConfirmationDialog: false,
      steps: [
        { number: 1, status: "step-active" },
        { number: 2, status: "step-disabled" }
      ],
      amenityList: {},
      filteredAmenities: []
    };
  },
  async created() {
    this.loading = true;
    let propertyIds = this.properties.map(property => property.id);
    await api
      .get(
        this,
        api.SPACE_MANAGEMENT + `amenities?properties=${propertyIds}`
      )
      .then(res => {
        res.amenities.forEach(item => {
          {
            Vue.set(
              this.amenityList,
              item.category,
              item.amenities.map(amenity => {
                if (this.selectedAmenitites && this.selectedAmenitites.length) {
                  this.selectedAmenitites.map(a => {
                    if (a.amenity_id === amenity.property_amenity_mapping_id) {
                      amenity.selected = true;
                      amenity.property_default_value = a.value;
                    }
                  });
                }
                amenity.options = amenity?.property_options
                  ? (amenity?.property_options != '') ? JSON.parse(amenity?.property_options):""
                  : (amenity?.options)?JSON.parse(amenity?.options):"";
                amenity.feature = amenity?.property_amenity_name
                  ? amenity?.property_amenity_name
                  : amenity?.master_amenity_name;
                return amenity;
              })
            );
          }
        });
        this.loading = false;
      })
      .catch(err => {
        this.loading = false;
        let desc = 'Something went wrong.!.';
        this.showMessageNotification({type: 'error', description: desc});
      });
  },
  methods: {
    onClickFilterSpaces() {
      this.getFilteredAmenities();
      this.applyFilter();
    },
    applyFilter() {
      let filterValue = [];
      this.filteredAmenities.map(amenity => {
        filterValue.push({
          amenity_id: amenity.property_amenity_mapping_id,
          key: amenity.feature,
          value: amenity.property_default_value
        });
      });
      let desc = this.filteredAmenities.length + " Filters have been applied.";
      this.showMessageNotification({type: 'success', description: desc});

      // close filter popup after applying filters with small delay.
      setTimeout(() => {
        this.$emit("applyFilter", filterValue);
        this.$emit("close");
      }, 300);
    },

    onCloseFilterDialog() {
      this.getFilteredAmenities();
      if (this.filteredAmenities && this.filteredAmenities.length) {
        this.showConfirmationDialog = true;
      } else {
        this.dialog = false;
        this.$emit("close");
      }
    },

    onCloseConfirmationDialog() {
      this.showConfirmationDialog = false;
    },

    getFilteredAmenities() {
      this.filteredAmenities = [];
      Object.keys(this.amenityList).map(key => {
        let amenities = this.amenityList[key];
        amenities.forEach(amenity => {
          if (amenity.selected) {
            this.filteredAmenities.push(amenity);
          }
        });
      });
    },

    resetFilter() {
      Object.values(this.amenityList).map(amenities => {
        if (amenities && amenities.length) {
          amenities.map(amenity => {
            if (amenity.selected) {
              amenity.selected = false;
            }
          })
        }
      })
      this.filteredAmenities = [];
    },
  },

  watch: {
    "errors.items"(val) {
      if(val && val.length) {
        let desc = 'There are errors in your form, correct them before continuing.';
        this.showMessageNotification({type: 'error', description: desc, list: val});
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.capitalize {
  text-transform: capitalize;
}
.add-border {
  border-style: dashed;
  border-color: lightgrey;
}
.text-grey {
  color: grey;
}
</style>
