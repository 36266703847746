<script type="text/babel">
import { AgGridVue } from 'ag-grid-vue'
import { AllModules } from 'ag-grid-enterprise'
import { mergeDeep } from '../../../utils/common'

import ClickRenderer from './ClickRenderer.vue'
import HoverRenderer from './HoverRenderer.vue'
import CustomRenderer from './CustomRenderer.vue'

const legacyProps = ['BIHelpers', 'key']
const legacyListeners = ['onInfoClick', 'onKebabClick']
const componentProps = {
	BIHelpers: {
		kebab: {
			enabled: false,
			icon: 'mdi-dots-vertical',
            component: null
		},
		info: {
			enabled: false,
			icon: 'mdi-information',
            component: null
		},
		custom: {
			enabled: false,
			icon: 'mdi-information',
            component: null
		}
	}
}

export default {
	inheritAttrs: false,
	props: {
		options: {
			type: Object,
			required: true,
			default: () => ({})
		}
	},
	components: {
		AgGridVue,
		ClickRenderer,
		HoverRenderer,
		CustomRenderer
	},
	data() {
		return {
			modules: AllModules,
			context: null
		}
	},
	beforeMount() {
		this.context = { parent: this }
	},
	methods: {
		insertIf(cond, ...items) {
			return cond ? items : []
		},
		decapitalize(str = '') {
			str = str ?? ''
			return str[0].toLowerCase() + str.slice(1)
		},
		_partition(source, predicate) {
			let entries = Object.entries(source),
				length = entries.length,
				partitions = new Array([], []),
				i = 0
			while (i < length) {
				let entry = entries[i]
				let passIndex = predicate.apply(this, entry) ? 0 : 1
				partitions[passIndex].push(entry)
				i += 1
			}
			return new Array(
				Object.fromEntries(partitions[0]),
				Object.fromEntries(partitions[1])
			)
		},
		getDataByPartitions(source, wildcards = []) {
			let [AG, BI] = this._partition(
				source,
				(key, value) => !wildcards.includes(key)
			)
			return { AG, BI }
		},
		dispatchEvent(combinedEvent) {
			let correspondingEvent = `on${combinedEvent.type}`
			if (
				typeof this.sanitizedOptions.listeners.BI[correspondingEvent] ===
				'function'
			) {
				this.sanitizedOptions.listeners.BI[correspondingEvent](
					combinedEvent.event
				)
			} else
				this.$emit(
					this.decapitalize(combinedEvent.type),
					combinedEvent.event
				)
		},
		getComponentConfOnType(action = 'click', { icon, definition, props, iconName } = {}) {
			return {
				hover: {
					cellRenderer: 'HoverRenderer',
					cellRendererParams: {
						icon,
						iconName,
						component: (definition && {
							definition: definition,
							userDefinedProps: props ?? {},
						})
					},
				},
				click: {
					cellRenderer: 'ClickRenderer',
					cellRendererParams: {
						icon,
						iconName,
						component: (definition && {
							definition: definition,
							userDefinedProps: props ?? {}
						}),
					},
				},
				custom: {
					cellRenderer: 'CustomRenderer',
					cellRendererParams: {
						icon,
						iconName,
						component: (definition && {
							definition: definition,
							userDefinedProps: props ?? {}
						}),
					},
				}
			}[action] ?? {}
		},
		setColumnDefs(api, columns) {
			let _columns = [].concat(...columns, ...this.mergedConfigs.columnDefs)
			api.setColumnDefs(_columns)
		}
	},
	computed: {
		/**
		 * @returns {Object} options by combining default values
		 */
		sanitizedOptions() {
			let combined = mergeDeep(
				{
					listeners: {},
					props: {
						...componentProps
					},
					class: [],
					style: []
				},
				this.options
			)
			combined.props = this.getDataByPartitions(
				combined.props,
				legacyProps
			)
			combined.listeners = this.getDataByPartitions(
				combined.listeners,
				legacyListeners
			)
			return combined
		},
		gridOptions() {
			return mergeDeep(
				this.mergedConfigs,
				this.sanitizedOptions.props.AG,
				this.sanitizedOptions.listeners.AG
			)
		},
		mergedConfigs() {
            const { BIHelpers = {} } = this.sanitizedOptions.props.BI ?? {}

			let config = [
				// Info conf
				...this.insertIf(
					BIHelpers.info.enabled,
					{
						editable: false,
						lockPosition: 'right',
						cellClass: 'locked-col pointer bi-helper-icons',
						width: 15,
						suppressNavigable: true,
						pinned: 'right',
						resizable: false,
						suppressMenu: true,
						...this.getComponentConfOnType(
							BIHelpers.info.action || 'click',
							{
								definition: BIHelpers.info?.component?.definition,
								props: BIHelpers.info?.component?.props,
								icon: BIHelpers.info?.icon,
								iconName: 'Info'
							}
						)
					}
				),
				// Kebab conf
				...this.insertIf(
					BIHelpers.kebab.enabled,
					{
						editable: false,
						lockPosition: 'right',
						cellClass: 'locked-col pointer bi-helper-icons',
						width: 15,
						suppressNavigable: true,
						pinned: 'right',
						resizable: false,
						suppressMenu: true,
						...this.getComponentConfOnType(
							BIHelpers.kebab.action || 'click',
							{
								definition: BIHelpers.kebab?.component?.definition,
								props: BIHelpers.kebab?.component?.props,
								icon: BIHelpers.kebab?.icon,
								iconName: 'Kebab'
							}
						)
					}
				),
				// Custom conf
				...this.insertIf(
					BIHelpers.custom.enabled,
					{
						editable: false,
						lockPosition: 'right',
						cellClass: 'locked-col pointer bi-helper-icons',
						width: 130,
						suppressNavigable: true,
						pinned: 'right',
						resizable: false,
						suppressMenu: true,
						...this.getComponentConfOnType(
							'custom',
							{
								definition: BIHelpers.custom?.component?.definition,
								props: BIHelpers.custom?.component?.props,
								icon: BIHelpers.custom?.icon,
								iconName: 'Custom'
							}
						)
					}
				)
			]
			let customProps = {
				suppressDragLeaveHidesColumns: true,
				suppressCellFocus: true,
				tooltipShowDelay: 1000,
                tooltipHideDelay: 3000,
				maxConcurrentDatasourceRequests: 1
			}

			return { columnDefs: config, ...customProps }
		}
	}
}
</script>

<template>
	<ag-grid-vue
		:class="sanitizedOptions.class"
		:style="sanitizedOptions.style"
		:gridOptions="gridOptions"
		:context="context"
		:modules="modules"
		:key="sanitizedOptions.props.BI.key"
	>
	</ag-grid-vue>
</template>

<style lang="scss" scoped>
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-balham.css";
::v-deep .pointer {
	cursor: pointer;
}
::v-deep .bi-helper-icons {
	display: flex;
	align-items: center;
	justify-content: center;
}
::v-deep .ag-pinned-right-header {
    background-color: #F4F6F8;
}
</style>
